import React from 'react'

const SocialItem = ({ social }) => {
    const { href, svg } = social;

    return (
        <li>
            <a target="_blank" href={href} rel="noreferrer">
                {svg}
            </a>
        </li>
    );
};

export default SocialItem