import Twitch from './games/Twitch'
import Youtube from './games/Youtube'
import Social from './social/Social'
import Setup from './setup/Setup'
// import About from './about/About'

function Main() {
    return (
        <main>
            {/* <About /> */}
            <Twitch />
            <Youtube />
            <Social />
            <Setup />
        </main>
    );
}

export default Main