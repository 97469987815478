import GameItem from "./game-item/game-item"

function Youtube() {

  const games = [
    {
      href: 'https://www.youtube.com/playlist?list=PL_T7rtViLO-tHDD2reBBY5a0eaoG8iR2I',
      src: 'https://static-cdn.jtvnw.net/ttv-boxart/506911_IGDB-285x380.jpg',
      alt: 'Capa DC Legends'
    },
    {
      href: 'https://www.youtube.com/playlist?list=PL_T7rtViLO-uxX_vIyP7RYAfIW1uJ-r3S',
      src: 'https://static-cdn.jtvnw.net/ttv-boxart/1039983646_IGDB-285x380.jpg',
      alt: 'Capa DC Legends'
    }
  ];

  return (
    <section>
      <div>
        <h2>Youtube</h2>

        <details>
          <summary>
            <p>Playlist dos jogos que faço live no Youtube</p>
            <i class=" fas fa-solid fa-chevron-down"></i>
          </summary>

          <ul class="games-list">

            {games.map((game, index) => (
              <GameItem key={index} game={game} />
            ))}

          </ul>

        </details>

      </div>
    </section>
  );
}

export default Youtube