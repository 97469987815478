import React from 'react'

const GameItem = ({ game }) => {
  const { href, src, alt } = game;

  return (
    <li>
      <a target="_blank" href={href} rel="noreferrer">
        <img src={src} alt={alt} />
      </a>
    </li>
  );
};

export default GameItem