function Header() {
    return (
        <header>
            <div>
                <a href="https://www.twitch.tv/unknow_bruno" target="_blank" rel="noreferrer">
                    <img
                        src="../../images/logo/logo.png" alt="Logo"
                    />
                </a>
            </div>
        </header>
    );
}

export default Header