import SetupItem from "./setup-item/setup-item"

function Setup() {

    const setup = [
        // items opcionais
        // class_reverso: alterna a ordem de exibição
        // width e height: ajusta o tamanho da imagem
        {
            class_reverso: 'reverso',
            equipamento: 'Notebook Dell Vostro 3460 | SSD 240 GB - Memória de 8 GB - Processador i5 3ª Geração',
            href: '#',
            src: '../../images/setup/dell-vostro-3460.png',
            alt: 'Notebook Dell Vostro 3460'
        },
        {
            equipamento: 'Monitor AOC 21 (E2270SWN)',
            href: 'https://www.mercadolivre.com.br/monitor-aoc-e2270swn-led-215-preto-100v240v/p/MLB6075218?matt_tool=39437454&matt_word=&matt_source=google&matt_campaign_id=14303413652&matt_ad_group_id=125984292877&matt_match_type=&matt_network=g&matt_device=c&matt_creative=539354956548&matt_keyword=&matt_ad_position=&matt_ad_type=pla&matt_merchant_id=735125422&matt_product_id=MLB6075218-product&matt_product_partition_id=1816373777646&matt_target_id=pla-1816373777646&gclid=Cj0KCQjwjryjBhD0ARIsAMLvnF-MkC0csPkXLGsZ2iP6a4Cvsy-WLZN2bw7ZNAQ9eP6T_xH0bsTs2REaAgaIEALw_wcB',
            src: '../../images/setup/monitor-aoc-21.png',
            alt: 'Monitor AOC 21',
            width: "120px",
        },
        {
            class_reverso: 'reverso',
            equipamento: 'Teclado Mecanico Gamer T-Dagger Bora RGB (T-TGK315)',
            href: 'https://www.kabum.com.br/produto/152114/teclado-mecanico-gamer-t-dagger-bora-rgb-switch-outemu-red-anti-ghosting-abnt2-preto-t-tgk315-rd-pt-red-',
            src: '../../images/setup/teclado-t-dagger-bora.png',
            alt: 'Teclado T-Dagger Bora'
        },
        {
            equipamento: 'Mouse Gamer T-Dagger Brigadeiro (T-TGM206)',
            href: 'https://www.kabum.com.br/produto/130698/mouse-gamer-t-dagger-brigadeiro-rgb-7-botoes-7200dpi-t-tgm206',
            src: '../../images/setup/mouse-t-dagger.png',
            alt: 'Mouse T-Dagger',
            height: '50px',
            width: '100px'
        },
        // {
        //     class_reverso: 'reverso',
        //     equipamento: 'Controle Joystick USB Midio',
        //     href: 'https://shopee.com.br/Controle-Joystick-Games-Console-e-Computador-Entrada-Usb-i.356064794.8675416213',
        //     src: '../../images/setup/joystick-midio.png',
        //     alt: 'Controle Joystick USB Midio',
        //     height: '80px'
        // },
        {
            class_reverso: 'reverso',
            equipamento: 'Controle Ipega 9076',
            href: 'https://www.amazon.com.br/Controle-Celular-Android-Bluetooth-Original/dp/B07T9TQYXW/ref=sr_1_4?adgrpid=1147891127914426&hvadid=71743362245819&hvbmt=be&hvdev=c&hvlocphy=667&hvnetw=o&hvqmt=e&hvtargid=kwd-71743900998474%3Aloc-20&hydadcr=17159_13443238&keywords=9076+ipega&qid=1705001479&sr=8-4&ufe=app_do%3Aamzn1.fos.6d798eae-cadf-45de-946a-f477d47705b9',
            src: '../../images/setup/ipega-9076.png',
            alt: 'Controle Ipega 9076',
            height: '80px',
            width: '80px'
        },
        {
            equipamento: 'Hub USB 3.0 - 7 portas',
            href: 'https://shopee.com.br/Seven-Portador-Hub-3.0-4-Portas-3.0-Splitter-2.0-Com-Quatro-usb-Para-Computador-i.742419097.20203358431',
            src: '../../images/setup/hub-usb.png',
            alt: 'Hub USB 3.0 - 7 portas',
            width: '80px',
            height: '80px'
        },
        {
            class_reverso: 'reverso',
            equipamento: 'Fone de Ouvido Havit Bluetooth i62',
            href: 'https://www.login.com.br/fones-de-ouvido/fone-de-ouvido-havit-bluetooth-i62-branco',
            src: '../../images/setup/havit-i62.png',
            alt: 'Fone de Ouvido Havit Bluetooth i62',
            width: '80px',
            height: '80px'
        },
        {
            equipamento: 'Headset Gamer Husky Gaming Storm (HGMD001)',
            href: 'https://www.kabum.com.br/produto/99464/headset-gamer-husky-gaming-storm-preto-usb-som-surrond-7-1-com-placa-de-som-drivers-50mm-rainbow-hgmd001',
            src: '../../images/setup/headset-husky-storm.png',
            alt: 'Headset Husky Gaming Storm'
        },
    ]

    return (
        <section>
            <div>

                <h2>Setup</h2>

                <details>
                    <summary>
                        <p>Meu setup utilizado para as lives.</p>
                        <i class=" fas fa-solid fa-chevron-down"></i>
                    </summary>

                    <ul class="setup-list">

                        {setup.map((setup, index) => (
                            <SetupItem key={index} setup={setup} />
                        ))}

                    </ul>

                </details>
            </div>
        </section>
    );
}

export default Setup