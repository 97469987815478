import React from 'react'

const SetupItem = ({ setup }) => {
    const { equipamento, href, src, alt, class_reverso, width, height } = setup;

    return (
        <li className={class_reverso}>
            <p>{equipamento}</p>
            <a target="_blank" href={href} rel="noreferrer">
                <img src={src} alt={alt} style={{ width, height }} />
            </a>
        </li>
    );
};

export default SetupItem