import GameItem from "./game-item/game-item"

function Twitch() {

  const games = [
    {
      href: 'https://www.twitch.tv/unknow_bruno/videos?filter=archives&category=73272',
      src: 'https://static-cdn.jtvnw.net/ttv-boxart/73272_IGDB-285x380.jpg',
      alt: 'Capa Real Racing 3'
    },
    {
      href: 'https://www.twitch.tv/unknow_bruno/videos?filter=archives&category=8271',
      src: 'https://static-cdn.jtvnw.net/ttv-boxart/8271_IGDB-285x380.jpg',
      alt: 'Capa Mad Max'
    },
    {
      href: 'https://www.twitch.tv/unknow_bruno/videos?filter=archives&category=506911',
      src: 'https://static-cdn.jtvnw.net/ttv-boxart/506911_IGDB-285x380.jpg',
      alt: 'Capa Need for Speed: No Limits'
    },
  ]

  return (
    <section>
      <div>
        <h2>Twitch</h2>

        <details>
          <summary>
            <p>Playlist dos jogos que faço live na Twitch</p>
            <i class=" fas fa-solid fa-chevron-down"></i>
          </summary>

          <ul class="games-list">

            {games.map((game, index) => (
              <GameItem key={index} game={game} />
            ))}

          </ul>

        </details>

      </div>
    </section>
  );
}

export default Twitch